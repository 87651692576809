<template>
    <div class="p-2">
        <NewUrl :domains="state.domains" :groups="state.groups" :badWords="state.badWords" @created="updateUrls"
                @toggle-groups="state.showGroups = !state.showGroups"
                @toggle-domains="state.showDomains = !state.showDomains"/>

        <Domains v-if="state.showDomains" :domains="state.domains" @changed="updateUrls" @domains-changed="updateDomains" @toggle-domains="state.showDomains = !state.showDomains"/>
        <Groups v-if="state.showGroups" :groups="state.groups" @changed="updateUrls" @groups-changed="updateGroups"  @toggle-groups="state.showGroups = !state.showGroups"/>
        <Urls :domains="state.domains" :urls="state.urls" :groups="state.groups" :badWords="state.badWords" @changed="updateUrls"/>
    </div>
</template>

<script>
import { reactive } from 'vue'

import NewUrl from './components/NewUrl';
import Urls from './components/Urls';
import Domains from './components/Domains';
import Groups from './components/Groups';

export default {
    props: {
        domains: Array,
        groups: Array,
        urls: Array,
        badWords: Array
    },
    components: {
        NewUrl,
        Urls,
        Domains,
        Groups
    },
    setup(props) {
        const state = reactive({
            urls: props.urls,
            domains: props.domains,
            groups: props.groups,
            badWords: props.badWords,
            showGroups: false,
            showDomains: false
        })
        return {state}
    },
    mounted() {
        this.updateUrls()
    },
    methods: {
        updateUrls() {
            axios.get('/url-builder/urls')
                .then((response) => {
                    this.state.urls = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        updateDomains() {
            axios.get('/url-builder/domains')
                .then((response) => {
                    this.state.domains = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        updateGroups() {
            axios.get('/url-builder/groups')
                .then((response) => {
                    this.state.groups = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },

    }
}

</script>
