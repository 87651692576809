<template>
    <div>
        <div class="form-group">
            <label for="bodis_account">Bodis Account</label>
            <select class="form-control" name="bodis_account" id="bodis_account" v-model="state.bodis_account"
                    :class="{ 'border border-danger': v$.bodis_account.$errors.length }">
                <option disabled selected value="">Select Bodis Account</option>
                <option v-for="(value, key) in accounts" :key="key" :value="key">{{key}}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="campaign_name">Campaign Name</label>
            <input type="text" name="campaign_name" class="form-control" id="campaign_name" placeholder="Campaign Name" v-model="state.campaign_name"
                   :class="{ 'border border-danger': v$.campaign_name.$errors.length }">
            <div v-if="v$.campaign_name.isUnique.$invalid" class="error-message">
                This Campaign Name is already taken!
            </div>
        </div>
        <div class="form-group form-new-domain">
            <label for="domain" class="d-flex justify-content-between">Domain <small v-if="req.namecheapBalance !== null">(Namecheap balance: ${{req.namecheapBalance}})</small></label>
            <div class="input-group">
                <input class="form-control py-2" type="search" id="domain" name="domain" placeholder="Domain" v-model="state.domain"
                       :class="{ 'border border-danger': v$.domain.$errors.length }">
                <span class="input-group-append">
                    <button class="btn btn-outline-success" type="button" id="domain_check" @click.prevent="checkAvailability"
                            :class="{'bg-danger text-white': req.currentTaken, 'bg-success text-white': req.validDomain} ">
                        <span v-if="req.validDomain">Available <span v-if="req.currentDomainPrice">(${{req.currentDomainPrice}})</span></span>
                        <span v-else-if="req.currentTaken">Available</span>
                        <span v-else>Check</span>
                    </button>
                </span>
            </div>
        </div>
        <div class="form-group">
            <label for="search_term_primary">Primary Search Term</label>
            <input type="text" name="search_term_primary" class="form-control" id="search_term_primary" placeholder="Search Term" v-model="state.search_term_primary"
                   :class="{ 'border border-danger': v$.search_term_primary.$errors.length }">
        </div>
        <div class="form-group">
            <label for="search_term_extra">Additional Search Term</label>
            <textarea class="form-control" name="search_term_extra" id="search_term_extra" v-model="state.search_term_extra"></textarea>
        </div>
        <div class="form-group">
            <label for="traffic_source">Traffic Source</label>
            <select class="form-control" name="traffic_source" id="traffic_source" v-model="state.traffic_source"
                    @change="$emit('ts-change', state.traffic_source)"
                    :class="{ 'border border-danger': v$.traffic_source.$errors.length }">
                <option disabled selected>Select Traffic Source</option>
                <option>Outbrain</option>
                <option>RevContent</option>
                <option>Taboola</option>
                <option>Yahoo</option>
                <option>MGID</option>
                <option>Baidu</option>
                <option>Facebook</option>
                <option>Google</option>
                <option>Twitter</option>
                <option>TikTok</option>
                <option>Snapchat</option>
                <option>Pinterest</option>
                <option>DSP</option>
                <option>Other</option>
            </select>
        </div>
    </div>
</template>

<script>
import {reactive} from 'vue'
import Loading from 'vue-loading-overlay';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
const { withAsync } = helpers;

export default {
    name: "Bodis.vue",
    components: {Loading},
    props: {
        name: String,
        country: Object,
        copy: {
            required: false,
            type: [Object, null],
            default: null
        },
        accounts: Object
    },
    setup(props) {
        const state = reactive({
            campaign_name: '',
            domain: '',
            search_term_primary: '',
            search_term_extra: '',
            traffic_source: '',
            bodis_account: ''
        });

        const req = reactive({
            currentTaken: false,
            validDomain: false,
            geo: props.country,
            currentDomainPrice: null,
            namecheapBalance: null
        })

        const mustBeValid = (value) => {
            return req.validDomain;
        }

        const isUniqueName = async (value) => {
            if (value === '') return true
            try {
                const response = await fetch(`/bodis/campaign-name-check/${value}`)
                return (await response.json()).valid
            } catch (error) {
                console.error(error)
                return false
            }
        };

        const rules = {
            campaign_name: {
                required,
                isUnique: withAsync(isUniqueName),
                $autoDirty: true
            },
            domain: {required, mustBeValid},
            search_term_primary: {required},
            traffic_source: {required},
            bodis_account: {required}
        }

        const balance = async () => {
            try {
                const response = await fetch(`/bodis/get-namecheap-balance`)
                return (await response)
            } catch (error) {
                console.error(error)
                return false
            }
        };

        if (req.namecheapBalance === null) {
            getNamecheapBalance()
        }

        async function getNamecheapBalance() {
            axios.get('/bodis/get-namecheap-balance' )
                .then((response) => {
                    req.namecheapBalance = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                })

                .finally(() => {
                });
        }

        const v$ = useVuelidate(rules, state)

        return {state, req, v$}
    },
    methods: {
        checkAvailability() {
            let form = $(this)

            let loader = this.$loading.show({
                container: this.$refs.newCampaign
            })

            axios.post('/bodis/domain-availability', {
                domain: this.state.domain
            }).then((response) => {
                const data = response.data

                if (data.status === 'ok' && data.available === 'true') {
                    loader.hide()
                    this.req.currentTaken = false;
                    this.req.validDomain = true;
                    this.req.currentDomainPrice = data.price;
                } else {
                    loader.hide()
                    this.req.currentTaken = true;
                    this.req.validDomain = false;
                }
            }).catch(function (error) {
                console.log(error);
                alert(error);
            })
            .finally(() => {
                loader.hide();

            });
        },
        async create() {
            return axios.post('/bodis/create', {
                campaign_name: this.state.campaign_name,
                domain: this.state.domain,
                search_term_primary: this.state.search_term_primary,
                search_term_extra: this.state.search_term_extra,
                traffic_source: this.state.traffic_source,
                geo: this.req.geo.code,
                bodis_account: this.state.bodis_account
            })
        }
    }
}
</script>

<style scoped>

</style>
