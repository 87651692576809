<template>
    <div>
        <h6 class="mt-1">URL Builder Setup</h6>
        <label for="ad_title">Fake Ad Title</label>
        <input type="text" class="form-control" placeholder="Fake Ad Title" name="ad_title" id="ad_title" v-model="state.ad_title"
               :class="{ 'border border-danger word-error': v$.ad_title.$errors.length }">

        <label for="track_type">Track Type</label>
        <select class="form-control" name="track_type" id="track_type" v-model="state.track_type"
                :class="{ 'border border-danger': v$.track_type.$errors.length }">
            <option value="">Tracking Type</option>
            <option>Adform</option>
            <option>MGID</option>
            <option>DSP</option>
            <option>Outbrain</option>
        </select>

        <label for="domain">Domain</label>
        <select class="form-control" name="domain" id="domain" v-model="state.domain"
                :class="{ 'border border-danger': v$.domain.$errors.length }">
            <option value="">Please Select</option>
            <option v-for="d in req.domains" :key="d.id" :value="d.id">{{d.domain}}</option>
        </select>

        <label for="group">Group</label>

        <select class="form-control" name="group" id="group" v-model="state.group"
                :class="{ 'border border-danger': v$.group.$errors.length }">
            <option value="">Please Select</option>
            <option v-for="gr in req.groups" :key="gr.id" :value="gr.id">{{gr.name}}</option>
        </select>
    </div>
</template>

<script>
import {onMounted, reactive, watch} from "vue";
import {required} from "@vuelidate/validators";
import { useVuelidate } from '@vuelidate/core'
import getBadWords from '../../API/badwords';


export default {
    name: "URLBuilder",
    setup(props, context) {
        const state = reactive({
            ad_title: '',
            track_type: '',
            domain: '',
            group: ''
        });

        const req = reactive({
            groups: [],
            domains: [],
            badWords: []
        })

        watch(() => state,
            (n, o) => {
                context.emit('input-changed', state)
            }, {
                immediate: true,
                deep: true
            })

        axios.get('/url-builder/domains')
            .then((response) => {
                req.domains = response.data
            })
            .catch((error) => {
                console.error(error)
            })


        axios.get('/url-builder/groups')
            .then((response) => {
                req.groups = response.data
            })
            .catch((error) => {
                console.error(error)
            })


        onMounted(async () => {
            const res = await getBadWords();
            req.badWords = res.data;
        });

        const mustNotHaveBadWords = (value) => {
            let valid = true;
            req.badWords.forEach((w) => {
                if (value.toLowerCase().includes(w)) {
                    valid = false;
                }
            })
            return valid;
        }


        const rules = {
            ad_title: { required, mustNotHaveBadWords, $autoDirty: true },
            domain: { required },
            track_type: { required },
            group: { required },
        }

        const v$ = useVuelidate(rules, state)

        return {state, req, v$}
    }
}
</script>

<style scoped>
.word-error {
    filter: drop-shadow(0px 0px 20px #FF0000);
}
</style>
