// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-11.use[1]!../../../../sass/tablesorter-blue.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badge-outline[data-v-73f1f6ca]{background-color:transparent;border:1px solid #999;color:#000;transition:background-color .3s}.badge-outline[data-v-73f1f6ca]:hover{background-color:#5b81a8}.badge-outline.badge-success[data-v-73f1f6ca]{border-color:#468847}.badge-outline.badge-success[data-v-73f1f6ca]:hover{background-color:transparent!important}.badge-outline.badge-primary[data-v-73f1f6ca]{border-color:#3490dc;color:#3490dc;font-weight:400;transition:background-color .3s}.badge-outline.badge-primary[data-v-73f1f6ca]:hover{background-color:#3490dc;color:#fff}.w-150[data-v-73f1f6ca]{width:130%}", "",{"version":3,"sources":["webpack://./resources/js/pages/SearchCampaign/components/Report.vue"],"names":[],"mappings":"AAGA,gCAGI,4BAAA,CADA,qBAAA,CADA,UAAA,CAIA,+BAFJ,CAII,sCACI,wBAFR,CAKA,8CACI,oBAFJ,CAII,oDACI,sCAFR,CAKA,8CACI,oBAAA,CACA,aAAA,CACA,eAAA,CACA,+BAFJ,CAII,oDACI,wBAAA,CACA,UAFR,CAMA,wBACI,UAHJ","sourcesContent":["\n@import \"/resources/sass/tablesorter-blue.css\";\n\n.badge-outline {\n    color: black;\n    border: 1px solid #999;\n    background-color: transparent;\n\n    transition: background-color 0.3s;\n\n    &:hover {\n        background-color: #5b81a8;\n    }\n}\n.badge-outline.badge-success {\n    border-color: #468847;\n\n    &:hover {\n        background-color: transparent!important;\n    }\n}\n.badge-outline.badge-primary {\n    border-color: #3490dc;\n    color: #3490dc;\n    font-weight: normal;\n    transition: background-color 0.3s;\n\n    &:hover {\n        background-color: #3490dc;\n        color: #fff;\n    }\n}\n\n.w-150 {\n    width: 130%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
