<template>
    <div class="container mt-2">
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-between px-3">
                    <h5 class="card-title">
                        URLs
                    </h5>
                    <div class="">
<!--                        <div class="col-4">-->
                            <form class="form-inline">
                                <div class="form-group">
                                    <input type="text" name="search" id="search" v-model="state.search" class="form-control form-control-sm"
                                        placeholder="Name"/>
                                </div>

                                <div class="form-group ml-1">
                                    <select class="form-control form-control-sm " name="category" id="category" v-model="state.group">
                                        <option value="">All Groups</option>
                                        <option v-for="gr in groups" :key="gr.id" :value="gr.id">{{gr.name}}</option>
                                    </select>
                                </div>

                            </form>
<!--                        </div>-->
                    </div>
                </div>

                <div class="row mb-1">


                </div>

                <table class="table table-hover table-bordered table-sm text-gray-900 mb-0 tablesorter sticky-header smaller-table mb-1"
                    >
                    <thead>
                    <tr>
                        <th scope="col" width="15%">Name</th>
                        <th scope="col" width="10%">Info</th>
                        <th scope="col">URL</th>
                        <th scope="col" width="15%">Actions</th>
                    </tr>
                    </thead>
                    <tbody v-for="gr in groups" :key="gr.id">
                        <tr class="bg-campaigns text-white pointer" v-if="filteredUrlsByGroup(gr.id).length > 0">
                            <td class="text-left pirmas pl-3 border-right-0 border-left-0">Category: {{gr.name}}</td>
                            <td class="border-right-0 border-left-0"></td>
                            <td class="border-0"></td>
                            <td class="border-0"></td>
                        </tr>
                        <tr v-for="u in filteredUrlsByGroup(gr.id)" :key="u.id">
                            <th scope="row">{{ u.name }}</th>
                            <td>{{ u.track_type }}</td>
                            <td class="final_url"><a href="#" @click.prevent="copyURL(u.final_url)">{{ u.final_url }}</a></td>
                            <td><span class="ml-1 badge badge-primary" @click="editUrl(u)">edit</span><span class="ml-1 badge badge-danger" @click="deleteUrl(u.id)">delete</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <Modal v-if="state.editing !== null" :title="'Editing ' + state.editing.name" @closed="state.editing = null;" :showFooter="false">
            <form @submit.prevent="updateUrl(state.editing)">
                <div class="row p-2">

                    <label for="name">Name</label>
                    <input type="text" class="form-control" placeholder="Name" name="name" id="name" v-model="state.editing.name"
                           :class="{ 'border border-danger': state.v$.name.$errors.length }">

                    <label for="voluum_url">Voluum URL</label>
                    <input type="text" class="form-control" placeholder="Voluum URL" name="voluum_url" id="voluum_url" v-model="state.editing.voluum_url"
                           :class="{ 'border border-danger': state.v$.voluum_url.$errors.length }">

                    <label for="ad_title">Fake Ad Title</label>
                    <input type="text" class="form-control" placeholder="Fake Ad Title" name="ad_title" id="ad_title" v-model="state.editing.ad_title"
                           :class="{ 'border border-danger': state.v$.ad_title.$errors.length }">


                    <label for="track_type">Track Type</label>
                        <select class="form-control" name="track_type" id="track_type" v-model="state.editing.track_type"
                            :class="{ 'border border-danger': state.v$.track_type.$errors.length }">
                            <option value="">Tracking Type</option>
                            <option>MGID</option>
                            <option disabled>Outbrain</option>
                    </select>


                    <label for="domain">Domain</label>
                    <select class="form-control" name="domain" id="domain" v-model="state.editing.domain"
                        :class="{ 'border border-danger': state.v$.domain.$errors.length }">
                        <option value="">Please Select</option>
                        <option v-for="d in domains" :key="d.id" :value="d.id">{{d.domain}}</option>
                    </select>

                    <label for="category">Group</label>

                    <select class="form-control" name="category" id="group" v-model="state.editing.group"
                        :class="{ 'border border-danger': state.v$.group.$errors.length }">
                        <option value="">Please Select</option>
                        <option v-for="gr in groups" :key="gr.id" :value="gr.id">{{gr.name}}</option>
                    </select>

                    <button type="submit" id="submit" class="btn btn-primary w-100 mt-1">Save</button>

                </div>
            </form>
        </Modal>
    </div>
</template>

<script>
import { reactive, computed } from "vue";
import Modal from './Modal';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    props: {
        urls: {
            type: Array,
            default: []
        },
        groups: {
            type: Array,
            default: []
        },
        domains: {
            type: Array,
            default: []
        },
        badWords: Array
    },
    components: {
      Modal
    },
    setup(props) {
        const state = reactive({
            search: '',
            group: '',
            editing: null,
            badWords: null,
            v$: null
        })

        state.badWords = props.badWords.map(element => {
            return element.toLowerCase();
        });

        const filteredUrls = computed(() => {
            return props.urls.filter(url => {
                return url.name.toLowerCase().includes(state.search.toLowerCase()) && (state.group !== '' ? url.group === state.group : true);
            })
        })

        const filteredUrlsByGroup = reactive((group) => {
            return props.urls.filter(url => {
                return url.group === group && url.name.toLowerCase().includes(state.search.toLowerCase()) && (state.group !== '' ? url.group === state.group : true)
            })
        })


        return {state, filteredUrls, filteredUrlsByGroup}
    },
    methods: {
        deleteUrl(id) {
            axios.delete('/url-builder/delete/' + id)
            .then(() => {
                this.$emit('changed');
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        editUrl(url) {
            this.state.editing = JSON.parse(JSON.stringify(url));


            const mustNotHaveBadWords = (value) => {
                let valid = true;
                this.state.badWords.forEach((w) => {
                    if (value.toLowerCase().includes(w)) {
                        valid = false;
                    }
                })
                return valid;
            }

            const rules = {
                name: { required, $autoDirty: true  },
                voluum_url: { required, $autoDirty: true  },
                track_type: { required, $autoDirty: true  },
                domain: { required, $autoDirty: true  },
                group: { required, $autoDirty: true  },
                ad_title: { required, mustNotHaveBadWords, $autoDirty: true }
            }

             this.state.v$ = useVuelidate(rules, this.state.editing)
        },
        async updateUrl(url) {
            const isFormCorrect = await this.state.v$.$validate()
            if (!isFormCorrect) return

            axios.put('/url-builder/update/' + url.id, url)
                .then(() => {
                    this.$emit('changed');
                    $('#modal').modal('hide');
                    this.state.editing = null;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async copyURL(mytext) {
            const el = document.createElement('textarea');
            el.value = mytext;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
        }
    }
}
</script>

<style scoped>
    .final_url {
        white-space: pre-wrap;
    }

    th, tr {
        padding-left: 5px;
    }

    td .badge {
        cursor: pointer;
    }
</style>
