<template>
    <div>
        <label for="campaign_name">Tonic Campaign Name</label>
        <input type="text" class="form-control" placeholder="Tonic Campaign Name" name="campaign_name" id="campaign_name" v-model="state.campaign_name"
               :class="{ 'border border-danger': v$.campaign_name.$errors.length }">

        <label for="offer">Offer</label>
        <v-select id="offer" :options="req.offers" v-model="state.offer" :searchable="true" label="name" class="mb-1"
                  :class="{ 'border border-danger': v$.offer.$errors.length }" :labelNotFound="props.country === '' ? 'Please select Geo' : 'No results for this geo'"/>

        <label for="keyword_amount">Keyword Amount</label>
        <v-select id="keyword_amount" :options="Array.from({length: 8}, (_, i) => i + 3)" v-model="state.keyword_amount" class="mb-1"
                  :class="{ 'border border-danger': v$.keyword_amount.$errors.length }"/>

        <label for="keyword_amount">Keywords <i>optional</i></label>
        <textarea id="keywords" v-model="state.keywords" cols="30" :rows="state.keyword_amount" class="form-control"></textarea>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {reactive, watch} from 'vue'

export default {
    name: "Tonic",
    props: {
        country: Object,
        copy: {
            required: false,
            type: [Object, null],
            default: null
        }
    },
    setup (props, context) {
        const state = reactive({
            campaign_name: '',
            offer: '',
            keyword_amount: 6,
            country_code: props.country,
            keywords: '',
            keywords_array: [],
        })

        const req = reactive({
            offers: [],
        })

        const rules = {
            campaign_name: {required},
            offer: {required},
            keyword_amount: {required}
        }

        const v$ = useVuelidate(rules, state)

        watch(() => props.country,
            (n, o) => {
                state.country_code = n
            })

        watch(() => state.keywords,
            (n, o) => {
                state.keywords_array = state.keywords.split(/\r?\n/).filter(element => element);
            })

        watch(() => props.country,
            (n, o) => {
                if(n !== o && n !== null)
                {
                    getTonicOffersForCountry(props.country.code)
                }
            }
        )

        watch(() => state,
            (n, o) => {
                context.emit('change', state)
            }, {
            deep:true,
                immediate: true
            })

        if (props.country !== '') {
            getTonicOffersForCountry(props.country.code)
        }

        async function getTonicOffersForCountry(country) {
            axios.get('/search-campaign/tonic/get-offers/' + country )
                .then((response) => {
                    req.offers = Object.values(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                })

                .finally(() => {
                });
        }

        if (props.copy !== null) {
            axios.get('/search-campaign/tonic/campaign/' + props.copy.offers[0].remote_id)
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        state.offer = {
                            id: data.offer_id,
                            name: data.offer
                        }
                        state.campaign_name = data.name

                        if (data.name.indexOf('ID_') !== -1) {
                            let nameSplit = data.name.split(' - ')
                            nameSplit.pop()
                            state.campaign_name = nameSplit.join(' - ')
                        }

                    }
                })
        }

        return {state, req, v$, props}
    },
    methods: {
        async create() {

            let campaignData = this.state;
            campaignData.country = campaignData.country_code.code

            return axios.post(
                '/search-campaign/tonic/create-campaign',
                campaignData
            )
        }
    }
}
</script>


