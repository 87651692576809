<template>
    <div class="p-2">

        <h3> {{ data.product.title }} <span class="px-1"></span> <a href="#" @click="new_translation = true"
                    data-toggle="modal" data-target="#addmodal" class="btn btn-secondary btn-small">Add translation</a></h3>

        <div class="mb-4">
            <strong>Filter languages</strong>
            <div class="form-inline">
                <div class="form-group">
                    <v-select
                        v-model="displayLangColumns"
                        :options="languages"
                        label="language"
                        :reduce="language => language.lc"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :multiple="true"></v-select>
                </div>
                <div class="form-group ml-2">
                    <button class="btn btn-danger" @click="displayLangColumns = []">Clear</button>
                </div>
                <div class="form-group ml-2">
                    <button class="btn btn-info" @click="displayLangColumns = Object.values(data.langs)">Reset</button>
                </div>
            </div>

        </div>

        <div v-for="( translation, index ) in data.translations" v-bind:key="translation.id">
            <div class="row d-block">
                <div class=" mt-1 d-block " style="padding: 2px;">
                    <div class="">

                        <table class="table table-fixed table-bordered w-auto bg-white" :id="index">
                            <thead>
                                <tr>
                                    <th scope="col" style="max-width: 60px"><input type="checkbox"
                                            style="width: 16px; height: 16px" v-model="checkboxes[index]"
                                            @change="toggleCheckboxes( checkboxes[index], index ) "></th>
                                    <th scope="col">{{ data.phrases[ index  ].title }}</th>
                                    <th scope="col" class="text-right" style="width:60px">Max
                                        {{ data.phrases[ index ].max_char }} </th>
                                    <template v-for="lang in displayLangColumns" :key="lang.id">
                                        <th scope="col" class="" > {{ lang  }}</th>
                                        <th scope="col" class="" >
                                            {{ data.phrases[ index  ].max_char }} </th>
                                    </template>

                                </tr>
                            </thead>
                            <tbody>


                                <tr v-for="item in translation" :key="item.id">

                                    <template v-if="item.phrase !== '-'">
                                    <td><input type="checkbox" class="form-control" style="width: 16px; height: 16px"
                                            v-model="item.checked" @change="handleCheckboxChange( index )"></td>
                                    <td scope="row"><a href="#" @click="showPopup(item)">{{item.phrase}}</a></td>
                                    <td class="text-right">{{ item.phrase.length }}</td>
                                    <template v-for="lang in displayLangColumns" v-bind:key="lang.id">
                                        <td>
                                            <ul class="phrases">
                                                <li v-for="phrase in item.orders[lang]" v-bind:key="phrase.id"
                                                    v-bind:class="getClassByStatus( phrase.status )"><span></span> <a
                                                        href="#" @click="phraseModal( phrase )">{{ phrase.phrase }}</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <small v-for="phrase in item.orders[lang]" v-bind:key="phrase.id"
                                                class="text-grey">{{ phrase.phrase.length }}</small>
                                        </td>
                                    </template>
                                    </template>

                                </tr>


                            </tbody>
                        </table>
                        <div class="card" style="width: 450px;">
                            <div class="card-body d-flex justify-content-around">
                                <input type="button" class="btn btn-info btn-small form-control-sm text-white"
                                    value="TRANSLATE" style="font-size: 12px;" @click="showMultiPopup( index )">

                                <!-- <input type="button" class="btn btn-info btn-small form-control-sm text-white"
                                    value="APPROVE" style="font-size: 12px;" @click="approveMultiple( index )"> -->

                                <input type="text" class="form-control form-control-sm" style="width: 200px;margin-bottom:0;"
                                    @focus="current_type = index" v-model="newPhrase[ index ]" @paste="handlePaste"
                                    v-on:keyup.enter="addNewPhrase( newPhrase[ index ], index )"
                                    placeholder="new phrase" />

                                <input type="button" class="btn btn-info btn-small form-control-sm  text-white"
                                    value="+" style="font-size: 12px"
                                    @click="addNewPhrase( newPhrase[ index ], index )">

                                <input type="button" class="btn btn-info btn-small form-control-sm  text-white"
                                    value="Export" style="font-size: 12px"
                                    @click="exportTable(index)">

                            </div>

                        </div>


                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade" id="addmodal" tabindex="-1" aria-labelledby="title" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">New Phrase</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">

                            <h3>New Phrase</h3>

                            <div class="form-group">
                                <label for="first_name">Phrase:</label>
                                <input type="text" class="form-control" name="phrase" v-model="current_phrase" />
                            </div>

                            <div class="form-group">
                                <label for="last_name">Type:</label>
                                <div class="form-group">
                                    <select class="form-control" name="phrase_type" v-model="current_type">

                                        <option v-for="phrase in data.phrases" v-bind:key="phrase.id"
                                            :value="phrase.id">{{ phrase.title }}</option>

                                    </select>
                                </div>
                            </div>

                            <input type="hidden" name="product_id" :value="this.productId" />

                            <button type="submit" class="btn btn-primary-outline"
                                @click="addNewPhrase( current_phrase, current_type )">Add phrase</button>



                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle" :text="modal.title">{{ this.modal.title }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="(typeof current_phrase !== 'object')">
                            <label for="id">Edit phrase</label>
                            <input name="id" type="text" v-model="phrase_updated" class="form-control"
                                :placholder="phrase_updated" data-lpignore="true">
                            <input type="button" class="btn btn-primary" value="Update"
                                @click="updatePhrase( current_phrase, phrase_updated  )" />
                            <input type="button" class="btn btn-danger" value="Delete"
                                @click="deletePhrase( current_phrase )" />
                            <hr>
                        </div>

                        <div class="form-group">
                            <label>Select languages for translation:</label>
                            <v-select v-model="current_language" :options="languages" track-by="language"
                                label="language" :multiple="false"></v-select>
                        </div>

                        <input type="button" class="btn btn-primary" value="Translate"
                            @click="translatePhrase( current_phrase )" />

                    </div>

                </div>
            </div>
        </div>

        <div class="modal fade" id="phraseModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle" :text="phrase_modal.title">
                            {{ this.phrase_modal.title }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group" v-html="phrase_modal.content">

                        </div>
                        <div v-if="phrase_modal.status == 'reviewable'">
                            <a href='#' class='btn btn-primary'
                                @click='approveTranslation( phrase_modal.id )'>Approve</a>
                            <a href='#' class='btn btn-danger' @click="phrase_modal.deny = true">Deny</a>
                        </div>

                        <div v-if="phrase_modal.deny == true">
                            <div class="m-3">
                                <input type="text" v-model="phrase_modal.comment" class="form-control"
                                    placeholder="Reason">
                                <a href='#' class='btn btn-primary' @click='denyTranslation( phrase_modal.id )'>Send
                                    Comment</a>
                            </div>
                        </div>

                        <div v-if="phrase_modal.status == 'done'">
                            <a href='#' class='btn btn-danger' @click='deleteTranslation( phrase_modal.id )'>Delete
                                Translation</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    // import Multiselect from 'vue-multiselect'
    import Swal from 'sweetalert2'


    import Plugin from 'jquery-sync-table-column-widths'

    const jQuery = require('jquery')
    const plugin = Plugin.register(jQuery)
    plugin.on('sync', table => {})

    export default {

        name: 'ProductPage',
        props: ['productId'],
        components: {
            Swal
        },
        data() {
            return {
                data: {
                    product: {
                        title: null
                    },
                    translations: {

                    },
                    langs: []
                },
                newPhrase: [],
                modal: {
                    title: null,
                    content: null,
                    status: null
                },
                languages: [],
                current_type: null,
                current_phrase: null,
                current_language: null,
                phrase_updated: null,
                phrase_modal: {
                    title: null,
                    content: null,
                    status: null,
                    deny: false
                },
                new_translation: false,
                loader: null,
                checkboxes: {},
                width: [

                ],
                displayLangColumns: null
            }
        },
        mounted() {
            this.getData();

            axios.get('/api/translation/languages').then((response) => {
                this.languages = response.data.response;

                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    },
                    icon: 'success',
                    title: 'Languages loaded'
                })
            });

        },
        computed: {
            translations() {
                let translations = this.data.translations;

                for (let item in translations) {
                    for(let v in translations[item]) {
                        if( translations[item][v]['phrase'] == '-' ) delete translations[item][v]
                    }
                }

                return this.data.translations;
            },
            filteredLanguages() {
                return Object.entries(this.data.langs).reduce((ini,[k,v])=>(ini[k]=v,ini),[]);
            }
        },
        watch: {
            displayLangColumns(newV, oldV) {
                this.$nextTick( () => { this.updateWidths() } );
            }
        },
        methods: {
            addNewPhrase(phrase, phrase_type) {
                if (phrase !== '') {
                    let data = {
                        phrase: phrase,
                        phrase_type: phrase_type,
                        product_id: this.productId
                    }
                    axios.post('/api/translation/add', data).then(response => {

                        this.newPhrase = [];

                        $('#addmodal').modal('hide')

                        this.getData();

                    }).then(() => {
                        $('#addmodal').modal('hide')
                        $(".modal-backdrop").remove();
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                            icon: 'success',
                            title: 'New Phrase Added'
                        })
                    }).catch(error => {
                        console.warn(error)
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                            icon: 'error',
                            title: 'Error Occured'
                        })
                    })
                } else {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'warning',
                        title: 'Phrase cannot be empty!'
                    })
                }
            },
            translatePhrase(id) {
                console.log(id)
                console.log(this.current_language);

                let data = {
                    'phrase': this.current_phrase,
                    'lang_to': this.current_language
                };

                if (typeof data.phrase == "object") {
                    data.phrase.forEach((v) => {
                        this.ajaxSubmitTranslate({
                            'phrase': v,
                            'lang_to': this.current_language
                        })
                    })
                } else {
                    this.ajaxSubmitTranslate(data)
                }



            },
            phraseModal(phrase) {
                $('#phraseModal').modal('show')

                this.phrase_modal.title = phrase.phrase;
                this.phrase_modal.content = "Current Status: " + phrase.status + ". ";
                this.phrase_modal.id = phrase.id;
                this.phrase_modal.status = phrase.status;

                if (phrase.status == 'reviewable') {
                    this.phrase_modal.content += "<br /><strong>Translation:</strong> " + phrase.phrase;
                    // this.phrase_modal.content += "<br /><strong>Original:</strong> " + this.data.translations;
                    this.phrase_modal.content += "<br />";
                }

            },
            approveTranslation(id) {
                this.showLoader();
                axios.post('/api/translation/approve', {
                    id: id
                }).then(response => {


                    this.getData();

                    $('#phraseModal').modal('hide')

                }).then(() => {
                    this.hideLoader();
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'success',
                        title: 'Phrase Approved!'
                    })
                }).catch(error => {
                    console.warn('noo')
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'error',
                        title: 'Error has occured!'
                    })
                })
            },
            denyTranslation(id) {
                this.showLoader();
                axios.post('/api/translation/deny', {
                    id: id,
                    comment: this.phrase_modal.comment
                }).then(response => {

                    this.getData();

                    $('#phraseModal').modal('hide')
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'success',
                        title: 'Phrase re-submitted for translation!'
                    })
                }).then(() => {
                    this.hideLoader();
                }).catch(error => {
                    console.warn(error)
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'error',
                        title: 'Error Occured!'
                    })
                })
            },
            showPopup(item) {

                this.current_phrase = item.id;
                this.modal.title = item.phrase
                this.phrase_updated = item.phrase;
                this.modal.content = 'fadsfasdf'
                this.modal.status = item.status
                $('#myModal').modal('show')
            },
            showMultiPopup(keys) {
                let array = this.data.translations[keys];
                this.current_phrase = [];

                array.forEach((v) => {
                    if (v.checked == true) {
                        this.current_phrase.push(v.id);
                    }
                })

                if (this.current_phrase.length) {
                    this.modal.title = 'Translate Multiple Phrases'
                    this.modal.content = 'fadsfasdf'
                    this.modal.status = 'open'
                    $('#myModal').modal('show')
                } else {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'error',
                        title: 'Please select a phrase!'
                    })
                }


            },
            deletePhrase(id) {
                this.showLoader();
                axios.post('/api/translation/delete', {
                    id: id
                }).then(response => {

                    this.getData();

                    $('#myModal').modal('hide')
                    this.hideLoader();
                }).then(() => {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'error',
                        title: 'Phrase Deleted!'
                    })
                }).catch(error => {
                    console.warn(error)
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'error',
                        title: 'Error has occured!'
                    })
                })
            },
            handlePaste(event) {

                //console.error(event.clipboardData.getData('text/plan'));

                let pastedText = event.clipboardData.getData('text/plain');

                var rows = pastedText.replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/mg, function (match, p1) {
                        // This function runs for each cell with multi lined text.
                        return p1
                            // Replace any double double-quotes with a single
                            // double-quote
                            .replace(/""/g, '"')
                            // Replace all new lines with spaces.
                            .replace(/\r\n|\n\r|\n|\r/g, ' ');
                    })
                    // Split each line into rows
                    .split(/\r\n|\n\r|\n|\r/g);

                console.log(rows)

                if (confirm('Add ' + rows.length + ' phrases?')) {

                    rows.forEach((item) => {
                        this.addNewPhrase(item, this.current_type)
                    })

                } else {
                    return false;
                }



            },
            getClassByStatus(status) {

                switch (status) {
                    case 'in_progress':
                        return 'status-in-progress';
                        break;
                    case 'done':
                        return 'status-done';
                        break;

                    case 'reviewable':
                        return 'status-reviewable';
                        break;

                    case 'rejected':
                        return 'status-rejected';
                        break;

                    case 'new':
                        return 'status-new';
                        break;

                    default:
                        return 'status-unknown';

                }

            },
            getData() {
                this.showLoader();
                axios.get('/api/product-page/' + this.productId)
                    .then(({
                        data
                    }) => {
                        this.data = data;

                        if(this.displayLangColumns == null) {
                            this.displayLangColumns = Object.values(data.langs);
                        }

                    }).then(() => {
                        $(function () {
                            $('[data-toggle="popover"]').popover({
                                html: true
                            })
                        })
                        this.availableLangs = Object.values(this.data.langs);
                    }).then(() => {
                        this.hideLoader();
                        this.updateWidths();
                    });
            },
            showLoader() {

                if (this.loader !== null)
                    this.loader.hide();

                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });
            },
            hideLoader() {
                this.loader.hide();
            },
            toggleCheckboxes(value, keys) {
                let array = this.data.translations[keys];
                array.forEach((v) => {
                    v.checked = value;
                })
            },
            handleCheckboxChange(keys) {
                let array = this.data.translations[keys];

                let values = array.map(function (d) {
                    return d.checked;
                }).every((v) => {
                    return v;
                });

                if (values == true) {
                    this.toggleCheckboxes(true, keys);
                } else {
                    this.checkboxes[keys] = false;
                }
            },
            ajaxSubmitTranslate(data) {
                axios.post('/api/translation/submit', data).then(response => {

                    this.current_language = '';
                    this.current_phrase = '';

                    this.getData();
                }).then(() => {
                    this.hideLoader();

                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'success',
                        title: 'Phrase Submitted for translation!'
                    })

                    $('#myModal').modal('hide');

                }).catch(error => {
                    console.warn(error)
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'error',
                        title: 'Error has occured'
                    })
                })
            },
            updatePhrase(current_phrase, phrase_updated) {
                this.showLoader();
                axios.post('/api/translation/update', {
                    id: current_phrase,
                    new: phrase_updated
                }).then(response => {


                    this.getData();

                    $('#myModal').modal('hide')

                }).then(() => {
                    this.hideLoader();
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'success',
                        title: 'Phrase updated!'
                    })
                }).catch(error => {
                    console.warn('noo')
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        },
                        icon: 'success',
                        title: 'Error Has Occured!'
                    })
                })
            },
            updateWidths() {

                let tableArr = document.getElementsByTagName('table');
                let cellWidths = [];
                let i, j;

                for (i = 0; i < tableArr.length; i++) {
                    for (j = 0; j < tableArr[i].rows[0].cells.length; j++) {
                        var cell = tableArr[i].rows[0].cells[j];
                        if (!cellWidths[j] || cellWidths[j] < cell.clientWidth)
                            cellWidths[j] = cell.clientWidth;
                    }
                }
                // set all columns to the widest width found
                for (i = 0; i < tableArr.length; i++) {
                    for (j = 0; j < tableArr[i].rows[0].cells.length; j++) {
                        tableArr[i].rows[0].cells[j].style.minWidth = cellWidths[j] + 'px';
                    }
                }

            },
            exportTable( id ) {
                // let instance = new TableExport(document.getElementById( id ), {
                //         formats: ['xls'],
                //         exportButtons: false,
                //         ignoreCols: [0]
                //     }
                // );
                //
                // let exportData = instance.getExportData()[id]['xls'];
                // let fileName = this.data.product.title + ' - ' + this.data.phrases[id].title;
                //
                // instance.export2file(exportData.data, exportData.mimeType, fileName, exportData.fileExtension);


            }

        }
    }

</script>
