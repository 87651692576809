<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">

                <h5 class="card-title">
                    Filters
                </h5>

                <div class="row">
                    <div class="col-md-2 col-sm-6 col-12 px-4">
                        <div class="form-group">
                            <label for="date-range"><b>Date range</b></label>
                            <input
                                type="text"
                                class="form-control border-secondary date-range-input disable-submit-onchange good-date-format"
                                id="date-range"
                                name="daterange">
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 px-4">
                        <div class="form-group">
                            <label for="authorNames"><b>Campaigns</b></label>
                            <v-select :options="campaigns" label="name" v-model="state.filters.campaigns" :reduce="campaign => campaign.id" multiple placeholder="Select Campaigns" :deselectFromDropdown="true">
                                <template #list-header>
                                    <li style="text-align: center">Top of the list!</li>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 px-4">
                        <div class="form-group">
                            <label for="authorNames"><b>Owner</b></label>
                            <v-select id="authorNames" :options="state.authorNames" v-model="state.filters.author" :reduce="author => author.id" class="mb-1" label="name" multiple placeholder="Select Owners"/>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 px-4">
                        <div class="form-group">
                            <label for="traffic_source"><b>Traffic Source</b></label>
                            <v-select id="traffic_source" :options="Object.values(state.trafficSources)" v-model="state.filters.traffic_source" class="mb-1" label="name" multiple placeholder="Select Traffic Source" />
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 px-4">
                        <div class="form-group">
                            <label for="geo"><b>Geo</b></label>
                            <v-select id="country_code" :options="state.countries" v-model="state.filters.geo" :reduce="country => country.code" class="mb-1" label="name" multiple placeholder="Select Geo"></v-select>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 col-12 px-4">
                        <div class="form-group">
                            <button type="submit" class="d-block w-100 btn btn-success" @click="(e) => saveFilters(state.currentFilterId, e)">
                                {{ state.currentFilterId ? 'Save Filter' : 'Create Filter' }}
                            </button>
                            <button type="submit" class="d-block w-100 btn mt-1 btn-info" @click="resetFilters()">Reset Filters</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ul class="nav nav-tabs pl-2 pr-2 d-none d-md-flex mt-1">
                    <li class="nav-item">
                        <a class="nav-link nav-link-height" :class="{'active': state.currentFilterId === null}" href="#" @click.prevent="resetFilters()">Default</a>
                    </li>
                    <template v-if="state.userFilters">
                        <li class="nav-item" v-for="userFilter in state.userFilters" :key="userFilter.id">
                            <div class="nav-link  nav-link-height d-flex align-items-center position-relative" :class="{'active': state.currentFilterId === userFilter.id}">
                                <a href="#" @click.prevent="setUserFilters(userFilter)">{{ userFilter.name }}</a>&nbsp;
                                    <form method="post" class="ml-1" action="#" v-if="state.currentFilterId === userFilter.id" @submit.prevent="deleteUserFilter(userFilter.id)">
                                        <input type="hidden" name="return_to" value=""/>
                                        <button type="submit" class="btn btn-link text-danger">x</button>
                                    </form>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>

        <div >
            <div>
                <table class="table table-hover table-bordered table-sm text-gray-900 mb-0 tablesorter sticky-header mt-1"
                       id="datatable"
                >
                    <thead>
                    <tr class="text-right">
                        <th scope="col" width="20%" class="pl-3 text-left">Name</th>
                        <th scope="col" width="10%">Visits (unique)</th>
                        <th scope="col" width="15%">Conversions</th>
                        <th scope="col" width="15%">Revenue ($)</th>
                        <th scope="col" width="5%" class="sorter-false">Actions</th>
                    </tr>
                    </thead>
                    <tbody class="text-right">
                        <template  v-for="c in state.campaigns" :key="c.id">
                            <tr  :class="c.status === 'ready' ? 'bg-white' : 'campaign-avg-error'">
                                <th scope="row" class="text-white bg-dark pl-3 d-flex justify-content-between">
                                    <div>
                                        <a href="#" class="text-white" @click.prevent="c.expanded = !c.expanded">{{ c.local_name }} - {{ c.country_code }}</a>&nbsp;
                                        <small>({{ c.offers.length }} offers)</small>
                                    </div>
                                    <div>
                                        <a href="#" @click.prevent="(e) => copyText(c.url, e)"><span class="ml-1 badge badge-outline text-white">url</span></a>
                                        <a href="#" @click.prevent="(e) => copyText('ID_'+c.id, e)"><span class="ml-1 badge badge-outline text-white">ID_{{c.id}}</span></a>
                                    </div>

                                </th>
                                <td>{{ c.visits }} ({{ c.uniqueVisits }})</td>
                                <td>{{ c.conversions }}</td>
                                <td>${{ parseFloat(c.revenue).toFixed(2) }}</td>
                                <td>
<!--                                    <a href="#" @click.prevent="c.expanded = !c.expanded"><span class="ml-1 badge badge-success">offers</span></a>-->
                                    <a href="#" @click.prevent="editCampaign(c)"><span class="ml-1 badge badge-primary badge-outline">edit</span></a>
                                    <a href="#" @click.prevent="copyCampaign(c)"><span class="ml-1 badge badge-primary badge-outline">copy</span></a>
                                </td>
                            </tr>
                            <tr class="offerRow" v-show="c.expanded" v-for="o in c.offers" :key="c.id + '-' + o.id">
                                <th scope="row" class="pl-2">{{o.type && o.type.toUpperCase() + ':'}} {{ o.name }}</th>
                                <td class="pl-2">{{ o.visits }} ({{ o.uniqueVisits }})</td>
                                <td class="pl-2">{{ o.conversions }}</td>
                                <td class="pl-2">${{ parseFloat(o.revenue).toFixed(2) }}</td>
                                <td class="pl-2">
                                    <a href="#" @click.prevent="editOffer(o)"><span class="ml-1 badge badge-primary badge-outline">edit</span></a>
                                </td>
                            </tr>
                        </template>
                    </tbody>

                    <tfoot>
                        <tr class="text-right table-primary pr-3">
                            <th scope="row" class="font-weight-bold">TOTALS</th>
                            <td>{{ totals.visits }} ({{ totals.uniqueVisits }})</td>
                            <td>{{ totals.conversions }}</td>
                            <td>${{ parseFloat(totals.revenue).toFixed(2) }}</td>
                            <td>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <EditCampaignModal :campaign="state.editingCampaign" v-if="state.editingCampaign !== null" @closed="state.editingCampaign = null"/>
        <EditOfferModal :offer="state.editingOffer" v-if="state.editingOffer !== null" @closed="state.editingOffer = null" />
    </div>
</template>

<script>
import { reactive, onMounted, watch, nextTick } from 'vue'
import moment from 'moment';
// import VSelect from '@alfsnd/vue-bootstrap-select'
import tablesorter from 'tablesorter';

import Countries from '../Helpers/countries';

import daterangepicker from 'daterangepicker';
import EditCampaignModal from './modals/EditCampaignModal';
import EditOfferModal from './modals/EditOfferModal';

export default {
    name: "Report.vue",
    props: {
        campaigns: Array,
        filters: Object,
        totals: Object,
        authorNames: Array,
        trafficSources: Object,
        userFilters: Array
    },
    components: {
        EditCampaignModal,
        EditOfferModal,
        daterangepicker,
        tablesorter
    },
    setup(props, context) {

        let defaultFilters = {
            dateRange: {
                startDate: props.filters.dates[0],
                endDate: props.filters.dates[1],
            },
            type: 'all',
            author: null,
            campaigns: null,
            geo: null,
            traffic_source: null
        }

        const state = reactive({
            campaigns: {},
            editingCampaign: null,
            editingOffer: null,
            authorNames: props.authorNames,
            trafficSources: Object.values(props.trafficSources),
            countries: Countries(),
            filters: JSON.parse(JSON.stringify(defaultFilters)),
            userFilters: props.userFilters,
            currentFilterId: null
        })

        onMounted((props) => {
            $('#date-range').daterangepicker({
                maxDate: moment(),
                autoApply: true,
                autoUpdateInput: true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days':  [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'Last 2 Months': [moment().subtract(2, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'Last 4 Months': [moment().subtract(4, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'Last 5 Months': [moment().subtract(5, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    '2 Month ago': [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')],
                    '3 Month ago': [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')],
                    '4 Month ago': [moment().subtract(4, 'month').startOf('month'), moment().subtract(4, 'month').endOf('month')],
                    '5 Month ago': [moment().subtract(5, 'month').startOf('month'), moment().subtract(5, 'month').endOf('month')],
                    '6 Month ago': [moment().subtract(6, 'month').startOf('month'), moment().subtract(6, 'month').endOf('month')]
                },
                locale: {
                    firstDay: 1
                }
            });
            $('#date-range').data('daterangepicker').setStartDate(new Date(state.filters.dateRange.startDate));
            $('#date-range').data('daterangepicker').setEndDate(new Date(state.filters.dateRange.endDate));

            $('#date-range').on('apply.daterangepicker', function(ev, picker) {
                const urlParams = new URL(location.href);

                urlParams.searchParams.set('startDate', picker.startDate.format('YYYY-MM-DD'));
                urlParams.searchParams.set('endDate', picker.endDate.format('YYYY-MM-DD'));
                location.assign(urlParams)
            });



            $("#datatable")
                .tablesorter({
                    theme : 'blue',
                    cssChildRow: "offerRow"
                });

        })

        resetFilters()
        getUserFilters()

        watch(() => state.filters,
            (newFilter) => {
                let camps = props.campaigns.map(e => {
                    return {expanded: false, ...e}
                })

                if (newFilter.geo !== null && newFilter.geo.length > 0) {
                    camps = camps.filter((campaign) => {
                        return newFilter.geo.includes(campaign.country_code);
                    })
                }

                if (newFilter.author !== null && newFilter.author.length > 0) {
                    camps = camps.filter((campaign) => {
                        return newFilter.author.includes(campaign.created_by);
                    })
                }

                if (newFilter.traffic_source !== null && newFilter.traffic_source.length > 0) {
                    camps = camps.filter((campaign) => {
                        return newFilter.traffic_source.includes(campaign.traffic_source);
                    })
                }

                if (newFilter.campaigns !== null && newFilter.campaigns.length > 0) {
                    camps = camps.filter((campaign) => {
                        return newFilter.campaigns.includes(campaign.id)
                    })
                }

                state.campaigns = camps;
            },
            {
                immediate: true,
                deep: true
            }
        )

        function resetFilters() {
            state.campaigns = props.campaigns.map(e => {
                return {expanded: false, ...e}
            })

            for (const [key, value] of Object.entries(defaultFilters)) {
                state.filters[key] = value;
            }

            state.currentFilterId = null;
        }

        function getUserFilters() {
            axios.get('/search-campaign/filters/').then((response) => {
                state.userFilters = response.data;

                const url = new URL(window.location.href);
                const filter = url.searchParams.get('filter');

                if (filter) {
                    const foundFilter = state.userFilters.find(f => f.id === parseInt(filter));
                    if (foundFilter) {
                        setUserFilters(foundFilter)
                    }
                }
            })
        }

        function saveFilters(current = null, e) {
            let name = null;
            if (current == null) {
                name = prompt('Enter Filter Name');
            }
            let filters = JSON.parse(JSON.stringify(state.filters))
            delete filters.dateRange;

            axios.post('/search-campaign/filters/save', {
                filter_id: current,
                name: name,
                filters: filters
            }).then((response) => {
                const data = response.data
                console.log(data)

                if (data.id) {
                    getUserFilters()
                    setUserFilters(response.data)
                }

                const badge = e.target;
                const currentBadgeText = badge.innerHTML;

                badge.innerHTML = 'Saved!';
                badge.classList.remove('btn-success');
                badge.classList.add('btn-dark');

                setTimeout(() => {
                    badge.innerHTML = currentBadgeText;
                    badge.classList.remove('btn-dark');
                    badge.classList.add('btn-success');

                }, 1500);
            })
        }

        function setUserFilters(filter) {
            let filterParsed = JSON.parse(filter.filter);
            let dateRange = state.filters.dateRange;

            filterParsed.dateRange = dateRange;

            state.currentFilterId = filter.id;
            state.filters = filterParsed;

            const url = new URL(window.location.href);
            url.searchParams.set('filter', filter.id);
            window.history.replaceState(null, null, url)
        }

        function deleteUserFilter(id) {
            if (confirm('Are you sure you want to delete this filter?')) {
                axios.delete('/search-campaign/filters/delete/' + id).then((response) => {
                    getUserFilters()
                    resetFilters()
                })
            }
        }


        return {
            state,
            resetFilters,
            saveFilters,
            setUserFilters,
            deleteUserFilter
        }
    },
    methods: {
        async copyText(mytext, e) {
            const badge = e.target;
            const currentBadgeText = badge.innerHTML;

            const el = document.createElement('textarea');
            el.value = mytext;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }

            badge.innerHTML = 'copied!';
            badge.classList.add('badge-success');

            setTimeout(() => {
                badge.innerHTML = currentBadgeText;
                badge.classList.remove('badge-success');
            }, 1500);
        },

        async copyCampaign(campaign) {
            this.$emit('copy-campaign', campaign);
            nextTick(() => {
                document.querySelector('#newCampaign').scrollIntoView({ behavior: 'smooth' });
            })
        },

        editCampaign(campaign) {
            this.state.editingCampaign = campaign;
        },

        editOffer(offer) {
            this.state.editingOffer = offer;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "/resources/sass/tablesorter-blue.css";

.badge-outline {
    color: black;
    border: 1px solid #999;
    background-color: transparent;

    transition: background-color 0.3s;

    &:hover {
        background-color: #5b81a8;
    }
}
.badge-outline.badge-success {
    border-color: #468847;

    &:hover {
        background-color: transparent!important;
    }
}
.badge-outline.badge-primary {
    border-color: #3490dc;
    color: #3490dc;
    font-weight: normal;
    transition: background-color 0.3s;

    &:hover {
        background-color: #3490dc;
        color: #fff;
    }
}

.w-150 {
    width: 130%;
}
</style>

<style scoped>
#authorNames > div > ul > li, #traffic_source > div > ul > li {
    text-transform: capitalize;
}

.vs__dropdown-option--selected {
    background: var(--vs-dropdown-option--deselect-bg);
    color: var(--vs-dropdown-option--deselect-color);
}

.vs__dropdown-option--selected:after {
    content: 'x';
    float: right;
    font-size: 9px;
    line-height: 2;
}

.vs__dropdown-toggle {
    height: calc(2.19rem + 2px) !important;
}
</style>
