<template>
    <div class="mb-2 container-fluid" ref="newCampaign" id="newCampaign">
        <div class="">
            <div class="card-body">
                <form @submit.prevent="createOffer(v$)">
                    <div class="form-row">
                        <div class="col-4">
                            <div class="card form-card-bg p-2">
                                <h5 class="card-title">Campaign Setup</h5>

                                <label for="local_campaign_name">Local Campaign Name</label>
                                <input type="text" class="form-control" placeholder="Local Campaign Name" name="local_campaign_name" id="local_campaign_name" v-model="state.local_campaign_name"
                                       :class="{ 'border border-danger': v$.local_campaign_name.$errors.length }">

                                <label for="country_code">Country</label>

                                <v-select id="country_code" :options="req.countries" v-model="state.country_code" class="mb-1" label="name" :searchable="true"
                                          :class="{ 'border border-danger': v$.country_code.$errors.length }"/>
                            </div>

                            <div class="card form-card-bg p-2 mt-2">
                                <h5 class="card-title">Voluum Setup</h5>

                                <label for="campaign_name">Voluum Campaign Name</label>
                                <input type="text" class="form-control" placeholder="Voluum Campaign Name" name="campaign_name" id="campaign_name" v-model="state.campaign_name"
                                       :class="{ 'border border-danger': v$.campaign_name.$errors.length }">

                                <label for="offer_url">Temporary Offer URL</label>
                                <input type="text" class="form-control" placeholder="Offer URL" name="offer_url" id="offer_url" v-model="state.offer_url"
                                       :class="{ 'border border-danger': v$.offer_url.$errors.length }">

                                <label for="workspace_id">Workspace</label>
                                <select class="form-control" name="workspace_id" id="workspace_id" v-model="state.workspace_id"
                                        :class="{ 'border border-danger': v$.workspace_id.$errors.length }">
                                    <option value="">Please Select</option>
                                    <option v-for="w in req.workspaces" :key="w.id" :value="w.id">{{w.name}}</option>
                                </select>

                                <label for="workspace_id">Traffic Source</label>
                                <select class="form-control" name="traffic_source" id="traffic_source" v-model="state.traffic_source"  @change="setTrafficSourceType"
                                        :class="{ 'border border-danger': v$.traffic_source.$errors.length }" :disabled="req.traffic_sources.length === 0">
                                    <option value="">Please Select</option>
                                    <option v-for="w in req.traffic_sources" :key="w.id" :value="w.id">{{w.name}}</option>
                                </select>

                                <template v-if="state.traffic_source_name === 'revcontent'">
                                    <label for="postback_api_key">Postback API Key</label>
                                    <input type="text" class="form-control" placeholder="Postback API Key" name="postback_api_key" id="postback_api_key" v-model="state.postback_api_key">
                                </template>

                            </div>


                        </div>

                        <div class="col-4">
                            <div class="card form-card-bg p-2">
                                <h5 class="card-title">Tracking Setup</h5>

                                <label for="tracking_type">Tracking Type</label>
                                <select class="form-control" name="tracking_type" id="tracking_type" v-model="state.track_type">
                                    <option value="">None</option>
                                    <option value="url-builder">URL Builder</option>
                                    <option value="fake_ad_title">Fake Ad Title</option>
                                    <option value="nativecpm">NativeCPM</option>
                                    <option value="nativecpm">Native Network</option>
                                </select>

                            </div>

                            <div class="card form-card-bg p-2 mt-2" v-if="state.track_type !== ''">

                                <div v-if="state.track_type === 'url-builder'">
                                    <URLBuilder @input-changed="(data) => state.url_builder = data"/>
                                </div>
                                <div v-else-if="state.track_type === 'fake_ad_title'">
                                    <FakeAdTitle @input-changed="(v) => state.fake_ad_title = v"/>
                                </div>
                                <div v-else-if="state.track_type === 'nativecpm'">
                                    <NativeCPM @input-changed="(v) => state.fake_ad_title = v"/>
                                </div>

                            </div>
                        </div>

                        <div class="col-4">
                            <div v-for="(offer, i) in state.offers" :key="i">
                                <div class="card form-card-bg p-2 mb-2">
                                    <h5 class="card-title">Offer Setup #{{i+1}}</h5>
                                    <label for="affiliate_network">Affiliate Network / Offer Type</label>
                                    <select class="form-control" name="affiliate_network" id="affiliate_network" v-model="offer.affiliate_network_id" @change="setAffiliateNetworkType(i)"
                                            :class="{ 'border border-danger': v$.offers.$each.$response ? v$.offers.$each.$response.$errors[i].affiliate_network_id.length : false }" :disabled="req.affiliate_networks.length === 0">
                                        <option value="">Please Select</option>
                                        <option v-for="w in req.affiliate_networks" :key="w.id" :value="w.id">{{w.name}}</option>
                                    </select>

                                    <label for="offer_name">Voluum Offer Name</label>
                                    <input type="text" class="form-control" placeholder="Voluum Offer Name" name="offer_name" id="offer_name" v-model="offer.voluum_offer_name"
                                           :class="{ 'border border-danger': v$.offers.$each.$response ? v$.offers.$each.$response.$errors[i].voluum_offer_name.length : false }">

                                    <label for="rotation_percentage">Rotation Percentage</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Voluum Rotation Percentage" name="rotation_percentage" id="rotation_percentage" v-model="offer.voluum_percentage"
                                               :class="{ 'border border-danger': v$.offers.$each.$response ? v$.offers.$each.$response.$errors[i].voluum_percentage.length : false }">
                                        <div class="input-group-append">
                                            <div class="input-group-text">%</div>
                                        </div>
                                    </div>

                                    <div v-if="offer.type === 'tonic' || offer.type == null">
                                        <Tonic :copy="copy" :ref="el => {offer_refs[i] = el}" :country="state.country_code" @created="(data) => createVoluumFlow(data)" :affiliateNetworks="req.affiliate_networks"/>
                                    </div>
                                    <div v-else-if="offer.type === 'bodis'">
                                        <Bodis :copy="copy" :ref="el => {offer_refs[i] = el}" :country="state.country_code" @created="(data) => createVoluumFlow(data)" @ts-change="(e) => offer.ts = e.toLowerCase()" :accounts="bodisAccounts"/>
                                    </div>

                                    <label for="rotation_percentage">Generated URL Suffix</label>
                                    <input type="text" class="form-control" placeholder="Generated URL Suffix" name="url_suffix" id="url_suffix" v-model="offer.url_suffix"
                                           :class="{ 'border border-danger': v$.offers.$each.$response ? v$.offers.$each.$response.$errors[i].url_suffix.length : false }">
                                </div>

                            </div>

                            <button v-if="state.offers.length > 1" type="submit" id="remove_offer" @click.prevent="state.offers.pop()" class="btn btn-danger mt-2 w-100">Remove Offer</button>
                            <button type="submit" id="extra_offer" @click.prevent="additionalOffer" class="btn btn-primary mt-2 w-100">Extra Offer</button>

                        </div>
                    </div>
                    <div class="form-row mt-2">
                        <div class="w-100">
                            <div class="alert alert-info" role="alert">
                                <button type="submit" id="submit" class="btn btn-primary mt-2 w-100">Create</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import {reactive, watch, ref, onBeforeUpdate} from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import Tonic from "./forms/Tonic";
import Bodis from "./forms/Bodis";
import URLBuilder from "./forms/URLBuilder";
import Countries from "../Helpers/countries";
import fixedEncodeURIComponent from "../Helpers/urlencode";
import FakeAdTitle from "./forms/FakeAdTitle";
import NativeCPM from "./forms/NativeCPM";


import {useLoading} from 'vue-loading-overlay'

export default {
    components: {FakeAdTitle, Tonic, URLBuilder, Bodis, NativeCPM, Countries},
    props: {
        copy: {
            required: false,
            type: [Object, null],
            default: null
        },
        bodisAccounts: Object
    },
    setup (props, context) {
        const defaults = {
            workspace_id: '6525f5e7-bcf2-4f31-9003-d18b97a1d4a8',
            affiliate_network_id: '00d50ed2-7889-45d5-9356-a272215e3777',
            traffic_source: '4babe662-ccbb-4b5c-b425-8d881e5fb56b',
            tracking_domain: 'go.serptrk.com'
        }

        const $loading = useLoading({
            // options
        });

        const offer_refs = ref([]);

        onBeforeUpdate(() => {
            offer_refs.value = [];
        });

        const state = reactive({
            campaign_name: '',
            local_campaign_name: '',
            country_code: {
                code: 'US',
                name: 'United States'
            },
            offer_url: 'https://google.com',
            traffic_source: '',
            workspace_id: '',
            track_type: '',
            domain: '',
            ad_title: '',
            group: '',
            tracking_domain: '',
            offers: [{
                type: 'tonic',
                affiliate_network_id: '',
                voluum_offer_name: '',
                voluum_percentage: 100,
                url_suffix: '',
                ts: '',
                data: []
            }],
            fake_ad_title: null,
            suffix: '',
            traffic_source_name: '',
            postback_api_key: '',
            url_builder: []
        })


        const req = reactive({
            workspaces: [],
            traffic_sources: [],
            affiliate_networks: [],
            tracking_type: '',
            countries: Countries(),
            loader: null
        });


        if (defaults.workspace_id) {
            state.workspace_id = defaults.workspace_id;
            state.traffic_source = defaults.traffic_source;
            state.tracking_domain = defaults.tracking_domain;
        }

        async function getWorkspaces() {
            axios.get('/search-campaign/voluum/workspaces')
                .then((response) => {
                    req.workspaces = response.data.workspaces;
                })
                .catch(function (error) {
                    console.log(error);
                })
        }

        getWorkspaces()


        async function getAffiliateNetworks(workspace_id) {
            axios.get('/search-campaign/voluum/aff-networks/' + workspace_id )
                .then((response) => {
                    req.affiliate_networks = Object.values(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        async function getTrafficSources(workspace_id) {
            axios.get('/search-campaign/voluum/traffic-sources/' + workspace_id )
                .then((response) => {
                    req.traffic_sources = Object.values(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        watch(() => state.workspace_id,
            (n, o) => {
                if (n !== o)
                {
                    req.traffic_sources = [];
                    req.affiliate_networks = [];
                    getAffiliateNetworks(n);
                    getTrafficSources(n);
                }
            }, {
                immediate: true
            })

        watch(() => state.offers,
            (n) => {
                const affN = req.affiliate_networks.find(({id}) => id === n[0].affiliate_network_id)
                if (affN)
                {
                    state.tracking_domain = affN.preferredTrackingDomain;
                }
            }, {immediate: true, deep: true})


        watch( () => state,
            () => generateSuffix(),
            {
                immediate: true,
                deep: true
            });

        function generateSuffix() {
            state.offers.forEach((offer) => {
                switch (offer.type) {
                    case "tonic":
                        switch (state.workspace_id) {
                            case "0089c03d-2f15-49c3-b50b-8f9d7760f5a9": // Banner ARB
                            case "6525f5e7-bcf2-4f31-9003-d18b97a1d4a8": // Content ARB
                                offer.url_suffix = '?network=mgid&site={var2}&adtitle={var10}&vcid={clickid}&data={var6}&sub_ID={var1}&subid1={var6}&subid2={clickid}';
                                break;
                            case "1c8b6e42-4e09-4c24-a733-dd8dfe40f4c2": // Native > Search ARB
                                let tracking = '';

                                switch (state.traffic_source_name) {
                                    case "outbrain":
                                        tracking = "&outbrainclickid={externalid}&subid1={var6}&subid2={clickid}&subid3={var1}&subid4={var2}|{var4}|{var6}";
                                        break;
                                    case "revcontent":
                                        tracking = "&subid2={clickid}&subid1={var3}&subid3={var1}&subid4={var2}|{var4}";
                                        break;
                                    case "mgid":
                                        tracking = "&sub_ID={externalid}&subid2={clickid}";
                                        break;
                                    case "taboola":
                                        tracking = "&click_id={externalid}&subid1={var6}&subid2={clickid}&subid3={var5}|{var1}|{var6}";
                                        break;
                                    case "yahoo":
                                        tracking = "&sub_ID={externalid}&subid2={clickid}";
                                        break;
                                }


                                offer.url_suffix = "?network=" +
                                    state.traffic_source_name
                                    + "&site={var3}&adtitle=" +
                                    fixedEncodeURIComponent(state.fake_ad_title)
                                    + "&dpco=1&vcid={clickid}" + tracking;
                                break;
                            case "088330e0-bc29-4069-a4d3-fed3541450b3":
                                offer.url_suffix = "?network=mgid&site={var2}&adtitle={var10}&vcid={clickid}&data={var6}&sub_ID={var1}";
                                break;
                            case "2a87a37c-a1e2-4724-a95b-7e6387baf380":
                                if (typeof state.url_builder.ad_title !== 'undefined') {
                                    offer.url_suffix = "?network={var3}&site={var1}&adtitle={var2}&click_id={var4}&vcid={clickid}&subid2={clickid}"
                                }
                                break;
                        }
                        break;
                    case "bodis":
                        switch (state.workspace_id) {
                            case "0089c03d-2f15-49c3-b50b-8f9d7760f5a9": // Banner ARB
                            case "6525f5e7-bcf2-4f31-9003-d18b97a1d4a8": // Content ARB
                                offer.url_suffix = "?ref_adnetwork="+offer.ts+"&ref_pubsite={var7}&ref_keyword={var10}&cid={clickid}&s2s_event_id=click&subid1={var6}&subid2={clickid}";
                                break;
                            case "1c8b6e42-4e09-4c24-a733-dd8dfe40f4c2": // Native > Search ARB
                                offer.url_suffix = "?ref_adnetwork="+offer.ts+"&ref_pubsite={var1}&ref_keyword="+fixedEncodeURIComponent(state.fake_ad_title)+"&cid={clickid}&s2s_event_id=click&subid1={var6}&subid2={clickid}";
                                break;
                            case "2a87a37c-a1e2-4724-a95b-7e6387baf380":
                                if (typeof state.url_builder.ad_title !== 'undefined') {
                                    offer.url_suffix = "?ref_adnetwork={var3}&ref_pubsite={var1}&ref_keyword={var2}&cid={clickid}&s2s_event_id=click&subid1={var6}&subid2={clickid}"
                                }
                                break;
                            case "946767be-42ad-4893-97f6-670d8db2008a":
                                if (typeof state.url_builder.ad_title !== 'undefined') {
                                    offer.url_suffix = "?ref_adnetwork="+offer.ts+"&ref_pubsite={var10}&ref_keyword={var5}&vcid={clickid}&data={var6}&sub_ID={var1}&subid1={var6}&subid2={clickid}"
                                }
                                break;
                        }
                    break;
                }
            })

        }



        const rules = {
            // offer_name: { required },
            campaign_name: { required },
            country_code: { required },
            offer_url: { required },
            traffic_source: { required },
            workspace_id: { required },
            // affiliate_network_id: { required },
            local_campaign_name: { required },
            offers: {
                $each: helpers.forEach({
                    offer_name: { required },
                    voluum_percentage: { required },
                    voluum_offer_name: { required },
                    affiliate_network_id: { required },
                    url_suffix: { required }
                })
            }
        }




        if (props.copy !== null) {

            const campaign = props.copy;

            console.log(campaign)

            state.campaign_name = campaign.name;
            state.local_campaign_name = campaign.local_name;

            if (campaign.name.indexOf('ID_') !== -1) {
                let nameSplit = campaign.name.split(' - ')
                nameSplit.pop()
                state.campaign_name = nameSplit.join(' - ')
            }

            campaign.offers.forEach((offer, key) => {
                state.offers[key] = {
                    type: 'tonic',
                    affiliate_network_id: '',
                    voluum_offer_name: '',
                    voluum_percentage: 100,
                    data: []
                };
                state.offers[key].offer_name = offer.name && campaign.name;
                state.offers[key].type = offer.type;

                if (offer.name.indexOf('ID_') !== -1) {
                    let nameSplit = offer.name.split(' - ')
                    nameSplit.pop()
                    state.offers[key].offer_name = nameSplit.join(' - ')
                }

                axios.get('/search-campaign/voluum/offer/get/' + offer.voluum_id)
                    .then((response) => {
                        if (response.status === 200) {
                            const data = response.data;
                            state.offers[key].affiliate_network_id = data.affiliateNetwork.id;
                            state.offers[key].tracking_domain = data.preferredTrackingDomain;
                            state.offers[key].voluum_offer_name = data.namePostfix;
                        }
                    })
            })


            axios.get('/search-campaign/voluum/campaign/get/' + campaign.voluum_id)
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        state.country_code = data.country;
                        state.workspace_id = data.workspace.id;
                        state.traffic_source = data.trafficSource.id;
                    }
                })

            context.emit('copy-ready')
        }

        const v$ = useVuelidate(rules, state, { $lazy: true })

        async function createOffer(v$) {
            const loader = $loading.show();

            const isFormCorrect = await v$.$validate()
            if (!isFormCorrect) {
                loader.hide()
                return
            }

            let results = [],
                promises = [];

            await Promise.all(offer_refs.value.map((item) => item.create())).then((values) => {
                values.forEach( (value, key) => {
                    results.push(value.data);
                    state.offers[key].data = value.data;
                })
                createVoluumFlow(results)
            })
            .catch(function (error) {
                // let errors = error.response.data.errors;
                if (error.response.data) {
                    alert(error.response.data)
                } else {
                    alert(error);
                }
                // console.log(error.response.data);
                loader.hide();
            })
            .finally(() => {
                console.log(results)
            });
        }

        async function createVoluumFlow() {
            const loader = $loading.show();

            axios.post('/search-campaign/voluum/create-flow', state)
                .then((response) => {
                    const data = response.data;

                    if (data && data.data.id) {
                        context.$emit('voluum-campaign-created', data)
                    }

                    console.log(data);
                    window.location.reload();

                })
                .catch(function (error) {
                    console.log(error);
                    alert(error.message)
                    loader.hide();
                });
        }


        async function additionalOffer() {
            state.offers.push({
                type: 'tonic',
                affiliate_network_id: '',
                voluum_offer_name: '',
                voluum_percentage: 100,
                data: []
            });
        }

        async function setAffiliateNetworkType(i) {
            const affN = req.affiliate_networks.find(({id}) => id === state.offers[i].affiliate_network_id)
            if (affN)
            {
                state.offers[i].type = affN.name.toLowerCase()
            }
        }

        async function setTrafficSourceType() {
            const tS = req.traffic_sources.find(({id}) => id === state.traffic_source)
            if (tS)
            {
                state.traffic_source_name = tS.name.toLowerCase()
            }
        }

        return { state, req, v$, setTrafficSourceType, setAffiliateNetworkType, createOffer, additionalOffer, createVoluumFlow, offer_refs}
    },
}
</script>

<style>
.form-card-bg {
    background: #5a91eb17!important;
}
.vs__dropdown-toggle {
    background: white;
}
.error-message {
    color: #c70000;
    font-size: 11px;
    padding-left: 10px;
}
</style>
