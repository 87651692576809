<template>
    <Modal :title="'Editing ' + offer.name" :show-footer="false" @closed="$emit('closed')">
        <form @submit.prevent="save">
            <div class="row p-2">

                <label for="voluum_name">Voluum Name</label>
                <input type="text" class="form-control" placeholder="Voluum Name" name="voluum_name" id="voluum_name" v-model="state.voluum_name"
                       :class="{ 'border border-danger': v$.voluum_name.$errors.length }">

                <label for="voluum_url">Voluum URL</label>
                <input type="text" class="form-control" placeholder="Voluum URL" name="voluum_url" id="voluum_url" v-model="state.voluum_url"
                       :class="{ 'border border-danger': v$.voluum_url.$errors.length }">

                <label for="remote_name">Remote Name ({{offer.type}})</label>
                <input type="text" class="form-control" placeholder="Remote Name" name="remote_name" id="remote_name" v-model="state.remote_name"
                       :class="{ 'border border-danger': v$.remote_name.$errors.length }">

                <button type="submit" id="submit" class="btn btn-primary w-100 mt-1">Save</button>

            </div>
        </form>
    </Modal>
</template>

<script>
import Modal from "./Modal";
import {reactive} from 'vue';

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import Loading from 'vue-loading-overlay';

export default {
    name: "EditOfferModal.vue",
    components: {
        Modal
    },
    props: {
        offer: Object
    },
    setup(props) {
        const state = reactive({
            id: props.offer.id,
            voluum_name: props.offer.name,
            remote_name: '',
            voluum_url: '',
        });

        const rules = {
            voluum_name: { required },
            remote_name: { required },
            voluum_url: { required },
        }

        axios.get('/search-campaign/voluum/offer/get/' + props.offer.voluum_id)
            .then((response) => {
                const data = response.data
                state.voluum_url = data.url;
            })

        if (props.offer.type === 'tonic') {
            axios.get('/search-campaign/tonic/campaign/' + props.offer.remote_id)
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;
                        state.remote_name = data.name
                    }
                })
        }

        const v$ = useVuelidate(rules, state)
        return {state, v$}
    },
    methods: {
        async save() {
            const loader = this.$loading.show({
                container: this.$refs.newCampaign
            })

            axios.post('/search-campaign/offer/edit', this.state)
            .catch(function (error) {
                console.log(error);
                alert(error.message)
                loader.hide();
            })
            .finally(() => {
                // loader.hide();
                window.location.reload();
            })
        }
    }
}
</script>

<style scoped>

</style>
