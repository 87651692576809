<template>
<div>
    <h5 class="card-title">Fake Network Setup</h5>

    <label for="fake_network_type">Fake Network Setup</label>
    <select class="form-control" name="fake_network_type" id="fake_network_type" v-model="fake_network_type">
        <option value="">None</option>
        <option value="mgid">MGID</option>
        <option value="outbrain">Outbrain</option>
        <option value="revcontent">RevContent</option>
        <option value="dsp">DSP</option>
    </select>
</div>
</template>

<script setup>
import {reactive} from "vue";

const fake_network_type = reactive(null)
</script>

<style scoped>

</style>
