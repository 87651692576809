<template>
    <Modal :title="'Editing ' + campaign.name" :show-footer="false"  @closed="$emit('closed')">
        <form @submit.prevent="save">
            <div class="row p-2">

                <label for="name">Local Name</label>
                <input type="text" class="form-control" placeholder="Local Name" name="name" id="name" v-model="state.local_name"
                       :class="{ 'border border-danger': v$.local_name.$errors.length }">

                <label for="voluum_name">Voluum Name</label>
                <input type="text" class="form-control" placeholder="Voluum Name" name="voluum_name" id="voluum_name" v-model="state.voluum_name"
                       :class="{ 'border border-danger': v$.voluum_name.$errors.length }">

                <button type="submit" id="submit" class="btn btn-primary w-100 mt-1">Save</button>

            </div>
        </form>
    </Modal>
</template>

<script>
import Modal from "./Modal";
import {reactive} from 'vue';

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import Loading from 'vue-loading-overlay';

export default {
    name: "EditCampaignModal.vue",
    components: {
        Modal
    },
    props: {
        campaign: Object
    },
    setup(props) {
        const state = reactive({
            local_name: props.campaign.local_name,
            voluum_name: props.campaign.name,
            id: props.campaign.id
        });

        const rules = {
            local_name: { required },
            voluum_name: { required },
        }

        const v$ = useVuelidate(rules, state)
        return {state, v$}
    },
    methods: {
        async save() {
            const loader = this.$loading.show({
                container: this.$refs.newCampaign
            })

            axios.post('/search-campaign/rename', this.state)
            .catch(function (error) {
                console.log(error);
                alert(error.message)
                loader.hide();
            })
            .finally(() => {
                window.location.reload();
            })
        }
    }
}
</script>

<style scoped>

</style>
