<template>
    <div class="container">
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-between px-3">
                    <h5 class="card-title">
                        New URL
                    </h5>
                    <div>
                        <button class="btn btn-outline-info" @click="isCollapsed = !isCollapsed">{{ isCollapsed ? '+' : '-'}}</button>
                        <button class="btn btn-outline-info" @click="$emit('toggle-groups')">Groups</button>
                        <button class="btn btn-outline-info" @click="$emit('toggle-domains')">Domains</button>
                    </div>
                </div>

                <form @submit.prevent="buildUrl"  v-if="!isCollapsed">
                    <div class="form-row">
                        <div class="col-6">
                            <label for="name">Name</label>
                            <input type="text" class="form-control" placeholder="Name" name="name" id="name" v-model="state.name"
                                   :class="{ 'border border-danger': v$.name.$errors.length }">

                            <label for="voluum_url">Voluum URL</label>
                            <input type="text" class="form-control" placeholder="Voluum URL" name="voluum_url" id="voluum_url" v-model="state.voluum_url"
                                   :class="{ 'border border-danger': v$.voluum_url.$errors.length }">

                            <label for="ad_title">Fake Ad Title</label>
                            <input type="text" class="form-control" placeholder="Fake Ad Title" name="ad_title" id="ad_title" v-model="state.ad_title"
                                   :class="{ 'border border-danger': v$.ad_title.$errors.length }">
                        </div>
                        <div class="col-3">
                            <label for="track_type">Track Type</label>
                            <select class="form-control" name="track_type" id="track_type" v-model="state.track_type"
                                    :class="{ 'border border-danger': v$.track_type.$errors.length }">
                                <option value="">Tracking Type</option>
                                <option>MGID</option>
                                <option disabled>Outbrain</option>
                            </select>

                            <label for="domain">Domain</label>
                            <select class="form-control" name="domain" id="domain" v-model="state.domain"
                                    :class="{ 'border border-danger': v$.domain.$errors.length }">
                                <option value="">Please Select</option>
                                <option v-for="d in domains" :key="d.id" :value="d.id">{{d.domain}}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <label for="group">Group</label>

                            <select class="form-control" name="group" id="group" v-model="state.group"
                                :class="{ 'border border-danger': v$.group.$errors.length }">
                                <option value="">Please Select</option>
                                <option v-for="gr in groups" :key="gr.id" :value="gr.id">{{gr.name}}</option>
                            </select>
                            <label for="submit">Build URL</label>

                            <button type="submit" id="submit" class="btn btn-primary  w-100">Build</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import { reactive, ref } from 'vue' // "from '@vue/composition-api'" if you are using Vue 2.x
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    props: {
        domains: Array,
        groups: Array,
        badWords: Array,
        updateUrls: Function
    },
    setup (props) {
        const state = reactive({
            name: '',
            voluum_url: '',
            track_type: '',
            domain: '',
            group: '',
            ad_title: ''
        })

        const badWords = props.badWords.map(element => {
            return element.toLowerCase();
        });

        const mustNotHaveBadWords = (value) => {
            let valid = true;
            badWords.forEach((w) => {
                if (value.toLowerCase().includes(w)) {
                    valid = false;
                }
            })
            return valid;
        }

        const rules = {
            name: { required },
            voluum_url: { required },
            track_type: { required },
            domain: { required },
            group: { required },
            ad_title: { required, mustNotHaveBadWords, $autoDirty: true }
        }

        const v$ = useVuelidate(rules, state)

        const groups = props.groups
        const domains = props.domains

        const isCollapsed = ref(false)
        const updateUrls = props.updateUrls

        return { state, v$, groups, domains, isCollapsed, updateUrls }
    },

    methods: {
        async buildUrl(e) {
            const isFormCorrect = await this.v$.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) return
            // actually submit form
            let t = this;
            axios.post('/url-builder/', this.state )
                .then(function (response) {
                    t.$emit('created');
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}
</script>

<style scoped>

</style>
