/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap'

import {createApp} from 'vue'
import {LoadingPlugin} from 'vue-loading-overlay';
/* Plugin Imports */
import vueCountryRegionSelect from 'vue-country-region-select'
import vSelect from 'vue-select'
const app = createApp({});



/* Plugin Styles */

import 'vue-select/dist/vue-select.css';
import 'vue-loading-overlay/dist/css/index.css';

/* Components */

app.component('product-page', require('./components/ProductPage.vue').default);
app.component('url-builder', require('./pages/UrlBuilder/Index.vue').default);
app.component('search-campaign', require('./pages/SearchCampaign/Index.vue').default);
app.component('voluum-report', require('./pages/VoluumReport/Index.vue').default);
app.component('v-select', vSelect)

/* Plugins */

app.use(LoadingPlugin);
app.mount('#app')
