<template>
    <div class="p-2">

        <div class="row justify-content-between px-3 mb-1">
            <h5 class="card-title">
                Search Campaigns
            </h5>
            <div>
                <button class="btn btn-primary" @click="toggleCreator()">{{state.newCampaign ? 'Close Window' : 'New Campaign' }}</button>
            </div>
        </div>


        <NewCampaign v-if="state.newCampaign" :copy="state.copyCampaign" :bodisAccounts="bodisAccounts"/>
        <Report :campaigns="campaigns" :filters="filters" :totals="totals" :authorNames="authorNames" :trafficSources="trafficSources" :userFilters="userFilters" @copy-campaign="(c) => copyCampaign(c)"/>

    </div>
</template>

<script>
import { reactive } from 'vue'
import NewCampaign from "./components/NewCampaign";
import Report from "./components/Report";

export default {
    props: {
        campaigns: Array,
        filters: Object,
        totals: Object,
        authorNames: Array,
        trafficSources: Object,
        userFilters: Array,
        bodisAccounts: Object
    },
    components: {
        NewCampaign,
        Report
    },
    setup(props, {expose}) {
        const state = reactive({
            newCampaign: false,
            copyCampaign: null,
            loading: null
        })


        function copyCampaign(campaign) {
            this.state.copyCampaign = campaign;
            this.state.newCampaign = true;
        }

        function toggleCreator() {
            this.state.newCampaign = !this.state.newCampaign
            this.state.copyCampaign = null;
        }

        return {state, toggleCreator, copyCampaign}
    }
}

</script>
<style lang="scss">
.v-select {
    height: calc(2.19rem + 2px) !important;
}
.v-select-toggle {
    height: calc(2.19rem + 2px) !important;
    line-height: 2 !important;
    border: 1px solid #ced4da!important;
}
.arrow-down {
    margin-top: 10px!important;
}
.v-dropdown-container {
    max-height: 350px;
    overflow: scroll;
}


#offer.border-danger, #country_code.border-danger {
    border: 0!important;

    button {
        border: 1px solid #e3342f!important;
    }
}

.date-range-input {
    height: initial!important;
}

</style>
