<template>
    <div class="container mt-2">
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-between px-3">
                    <h5 class="card-title">
                        Groups
                    </h5>
                    <button class="btn btn-outline-info" @click="closeCard">Close</button>
                </div>

                <div class="row">
                    <div class="col-4">
                        <div class="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action" :class="{'active text-white': state.editing == null}" @click="state.editing = null; state.group = ''" href="#">Add New</a>

                            <a class="list-group-item list-group-item-action" :class="{'active text-white': state.editing === g.id}"
                                v-for="g in groups" :key="g.id" @click="state.editing = g.id; state.group = g.name">{{ g.name }}</a>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                                <form @submit.prevent="handleGroup">
                                    <label for="group">Group</label>
                                    <input type="text" name="group" id="group" v-model="state.group" class="form-control"/>

                                    <div class="form-inline">
                                        <input type="submit" class="btn btn-primary" value="Save"/>
                                        <button class="btn btn-danger ml-1" v-if="state.editing !== null && state.group !== 'Uncategorized'" @click.prevent="deleteGroup">Delete</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed } from "vue";

export default {
    props: {

        groups: {
            type: Array,
            default: []
        },

    },
    setup(props) {
        const state = reactive({
            editing: null,
            group: ''
        })

        return {state}
    },
    methods: {
        handleGroup() {
            if (this.state.editing) {
                axios.put('/url-builder/groups/update/' + this.state.editing, {
                    id: this.state.editing,
                    group: this.state.group
                })
                .then(() => {
                    this.$emit('groups-changed');
                    this.$emit('changed');
                    this.$emit('toggle-groups');
                    this.state.editing = null;
                    this.state.group = '';
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                axios.post('/url-builder/groups/create/', {
                    group: this.state.group
                })
                    .then(() => {
                        this.$emit('groups-changed');
                        this.$emit('changed');
                        this.$emit('toggle-groups');
                        this.state.editing = null;
                        this.state.group = '';
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },

        deleteGroup() {
            let res = confirm('Are you sure? This is not reversible!');

            if (res) {
                axios.delete('/url-builder/groups/delete/' + this.state.editing)
                    .then(() => {
                        this.$emit('groups-changed');
                        this.$emit('changed');
                        this.$emit('toggle-groups');
                        this.state.editing = null;
                        this.state.group = '';
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },

        closeCard() {
            this.$emit('groups-changed');
            this.$emit('changed');
            this.$emit('toggle-groups');
            this.state.editing = null;
            this.state.group = '';
        }

    }
}
</script>

<style scoped>
.final_url {
    white-space: pre-wrap;
}

th, tr {
    padding-left: 5px;
}

td .badge {
    cursor: pointer;
}
</style>
