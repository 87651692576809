<template>
<div>
    <h6 class="mt-1">Ad Title Setup</h6>
    <label for="ad_title">Fake Ad Title</label>
    <input type="text" class="form-control" placeholder="Fake Ad Title" name="ad_title" id="ad_title" v-model="state.ad_title"
           :class="{ 'border border-danger': v$.ad_title.$errors.length }" @change="handleChange()">
</div>
</template>

<script>
import {onMounted, reactive} from "vue";
import {required} from "@vuelidate/validators";
import {useVuelidate} from '@vuelidate/core'
import getBadWords from '../../API/badwords';

export default {
    name: "FakeAdTitle",
    setup(props, context) {
        const state = reactive({
            ad_title: '',
        });

        const req = reactive({
            badWords: []
        })

        onMounted(async () => {
            const res = await getBadWords();
            req.badWords = res.data;
        });

        const mustNotHaveBadWords = (value) => {
            let valid = true;
            req.badWords.forEach((w) => {
                if (value.toLowerCase().includes(w)) {
                    valid = false;
                }
            })
            return valid;
        }

        const rules = {
            ad_title: { required, mustNotHaveBadWords, $autoDirty: true },
        }

        const v$ = useVuelidate(rules, state)

        function handleChange() {
            context.emit('input-changed', this.state.ad_title)
        }


        return {state, req, v$, handleChange}
    }
}
</script>
