<template>
    <div class="mb-2 container-fluid" ref="newCampaign" id="newCampaign">
        <div class="">
            <div class="card-body">
                <form @submit.prevent="createList">
                    <div class="form-row">


                        <div class="col-3">
                            <strong>Find Campaigns</strong><br>

                            <label for="workspace_id">Workspace</label>
                            <select class="form-control" name="workspace_id" id="workspace_id" v-model="workspace_id">
                                <option value="">Please Select</option>
                                <option v-for="w in workspaces" :key="w.id" :value="w.id">{{w.name}}</option>
                            </select>
                            <div class="p-2">
                                <label for="local_campaign_name">Select Campaigns</label>
                                <v-select :options="campaigns" v-model="selected" multiple @search="onSearch" label="campaignName" :closeOnSelect="false"/>
                            </div>
                        </div>
                        <div class="col-6 px-2">
                            <strong>Selected Campaigns</strong>
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(campaign, key) in selected" :key="campaign.id">
                                    {{campaign.campaignName}}
                                    <a href="#" @click.prevent="remove(campaign.campaignId)">
                                        <span class="badge badge-danger badge-pill cursor-pointer">remove</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-3">
                            <strong>Create List</strong>

                            <div class="form-group">
                                <label for="name">List Name</label>
                                <input type="text" class="form-control" v-model="name" id="name"/>

                                <button type="submit" class="btn btn-primary">Save</button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>
export default {
    name: "NewList",
    props: {
        workspaces: Array
    },
    data() {
        return {
            campaigns: [],
            selected: [],
            name: '',
            workspace_id: ''
        }
    },
    watch: {
      workspace_id(newV) {
          fetch(
              `/voluum-report/search/-/${newV}`
          ).then(res => {
              res.json().then(json => this.setCampaigns(json.rows));
          });
        }
    },
    methods: {
        setCampaigns(c) {
          this.campaigns = c;
        },
        onSearch(search, loading) {
            console.log('on')
            if(search.length) {
                loading(true);
                this.fetchOptions(loading, search, this);
            }
        },
        fetchOptions: _.debounce((loading, search, vm) => {
            fetch(
                `/voluum-report/search/${escape(search)}/${vm.workspace_id}`
            ).then(res => {
                res.json().then(json => (vm.campaigns = json.rows));
                loading(false);
            });
        }, 350),
        remove(id) {
            this.selected.splice(this.selected.findIndex(a => a.campaignId === id) , 1)
        },
        createList() {
            if (this.selected.length === 0) {
                return alert('Select at least 1 campaign');
            }

            if (this.name === '') {
                return alert('Enter name!')
            }

            const finalList = this.selected.map((campaign) => {
                return campaign.campaignId;
            });

            axios.post('/voluum-report/search/save', {
                name: this.name,
                campaigns: finalList
            }).then((response) => {
                alert(response.data);
                window.location.reload();
            }).catch((err) => {
                alert(err.data);
            })

        }
    }
}
</script>

<style>
.vs__dropdown-option--selected {
    background: var(--vs-dropdown-option--deselect-bg);
    color: var(--vs-dropdown-option--deselect-color);
}

.vs__dropdown-option--selected:after {
    content: 'x';
    float: right;
    font-size: 9px;
    line-height: 2;
}
</style>
