<template>
    <div>
        <div class="p-2">

            <div class="row justify-content-between px-3 mb-1">
                <h5 class="card-title">
                    Voluum Report
                </h5>
                <div>
                    <button class="btn btn-primary" @click="newList = !newList">New List</button>
                </div>
            </div>


            <NewList v-if="newList" :workspaces="workspaces"/>

            <div class="card-body d-flex align-content-end justify-content-end">
                <div class="form-group">
                    <label for="date-range"><b>Date range</b></label>

                    <input
                        type="text"
                        class="form-control border-secondary date-range-input good-date-format"
                        id="date-range"
                        value=""
                        name="daterange">
                </div>
            </div>

            <div class="card-body">
                <ul class="nav nav-tabs pl-2 pr-2 d-none d-md-flex mt-1">
<!--                    <li class="nav-item">-->
<!--                        <a class="nav-link nav-link-height" :class="{'active': currentListId === null}" href="#" @click.prevent="resetFilters()">Default</a>-->
<!--                    </li>-->
                    <template v-if="lists">
                        <li class="nav-item" v-for="list in lists" :key="list.id">
                            <div class="nav-link  nav-link-height d-flex align-items-center position-relative" :class="{'active': currentList === list}">
                                <a href="#" @click.prevent="setCurrentList(list)">{{ list.name }}</a>&nbsp;
<!--                                <form method="post" class="ml-1" action="#" v-if="state.currentFilterId === userFilter.id" @submit.prevent="deleteUserFilter(userFilter.id)">-->
<!--                                    <input type="hidden" name="return_to" value=""/>-->
<!--                                    <button type="submit" class="btn btn-link text-danger">x</button>-->
<!--                                </form>-->
                            </div>
                        </li>
                    </template>
                </ul>
            </div>



            <div class="card-body" v-if="currentData">
                <table class="table table-hover table-bordered table-sm text-gray-900 mb-0 tablesorter sticky-header mt-1"
                       id="datatable"
                >
                    <thead>
                    <tr class="text-right">
                        <th scope="col" width="20%" class="pl-3 text-left">Name</th>
                        <th scope="col" width="10%">Visits (unique)</th>
                        <th scope="col" width="15%">Conversions</th>
                        <th scope="col" width="15%">Revenue ($)</th>
                        <th scope="col" width="5%" class="sorter-false">EPV</th>
                    </tr>
                    </thead>
                    <tbody class="text-right">
                    <template  v-for="c in currentData.rows" :key="c.campaignId" >
                        <tr class="text-white bg-dark ">
                            <th scope="row" class="text-white bg-dark pl-3 d-flex justify-content-between">
                                {{c.campaignName}}
                            </th>
                            <td class="text-white bg-dark">{{ c.visits }} ({{ c.uniqueVisits }})</td>
                            <td class="text-white bg-dark">{{ c.conversions }}</td>
                            <td class="text-white bg-dark">${{ parseFloat(c.revenue).toFixed(2) }}</td>
                            <td class="text-white bg-dark">
                                ${{ c.epv }}
                            </td>
                        </tr>
                        <tr class="offerRow" v-for="o in c.offers" :key="o.offerId">
                            <th scope="row" class="pl-2 bg-white text-dark">{{ o.offerName }}</th>
                            <td class="pl-2">{{ o.visits }} ({{ o.uniqueVisits }})</td>
                            <td class="pl-2">{{ o.conversions }}</td>
                            <td class="pl-2">${{ parseFloat(o.revenue).toFixed(2) }}</td>
                            <td class="pl-2">${{ o.epv }}</td>
                        </tr>
                    </template>
                    </tbody>

                    <tfoot>
                    <tr class="text-right table-primary pr-3">
                        <th scope="row" class="font-weight-bold">TOTALS</th>
                        <td>{{ currentData.totals.visits }} ({{ currentData.totals.uniqueVisits }})</td>
                        <td>{{ currentData.totals.conversions }}</td>
                        <td>${{ currentData.totals.revenue }}</td>
                        <td>${{ currentData.totals.epv }}
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import NewList from "./components/NewList";
import Loading from 'vue-loading-overlay';
import daterangepicker from 'daterangepicker';
import moment from "moment/moment";
import tablesorter from 'tablesorter';

export default {
    name: "Index",
    components: {NewList,Loading,daterangepicker,tablesorter},
    props: {
        lists: Array,
        workspaces: Array,
    },
    data() {
        return {
            // lists: [],
            currentList: null,
            currentData: null,
            loader: null,
            newList: false,
            startDate: new Date().toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0]
        }
    },
    mounted() {
        $('#date-range').daterangepicker({
            maxDate: moment(),
            autoApply: true,
            autoUpdateInput: true,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days':  [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Last 2 Months': [moment().subtract(2, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Last 4 Months': [moment().subtract(4, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Last 5 Months': [moment().subtract(5, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                '2 Month ago': [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')],
                '3 Month ago': [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')],
                '4 Month ago': [moment().subtract(4, 'month').startOf('month'), moment().subtract(4, 'month').endOf('month')],
                '5 Month ago': [moment().subtract(5, 'month').startOf('month'), moment().subtract(5, 'month').endOf('month')],
                '6 Month ago': [moment().subtract(6, 'month').startOf('month'), moment().subtract(6, 'month').endOf('month')]
            },
            locale: {
                firstDay: 1
            }
        });

        //
        $('#date-range').on('apply.daterangepicker', (ev, picker) => {
            // const urlParams = new URL(location.href);

            this.startDate = picker.startDate.format('YYYY-MM-DD');
            this.endDate = picker.endDate.format('YYYY-MM-DD');
            // urlParams.searchParams.set('startDate', picker.startDate.format('YYYY-MM-DD'));
            // urlParams.searchParams.set('endDate', picker.endDate.format('YYYY-MM-DD'));
            // location.assign(urlParams)
            this.loader = this.$loading.show({
                container: this.$refs.newCampaign
            })
            console.log(picker.startDate)
            this.getNewData(this.currentList);
        });



    },
    watch: {
      currentList (newV) {
          this.getNewData(newV)
      },
    },
    methods: {
        setCurrentList(list) {
            this.loader = this.$loading.show({
                container: this.$refs.newCampaign
            })
            this.currentList = list;
        },
        getNewData(list) {
            axios.post('/voluum-report/search/get', {
                campaigns: list.campaigns,
                startDate: this.startDate,
                endDate: this.endDate
            }).then((response) => {
                console.log(response.data)
                this.currentData = response.data
            }).finally(() => {
                this.loader.hide();

                $("#datatable")
                    .tablesorter({
                        theme : 'blue',
                        cssChildRow: "offerRow"
                    });

                // $('#date-range').data('daterangepicker').setStartDate(new Date(this.currentData.responseParams.timeRange.from));
                // $('#date-range').data('daterangepicker').setEndDate(new Date(this.currentData.responseParams.timeRange.to));
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@import "/resources/sass/tablesorter-blue.css";

</style>
